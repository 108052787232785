import { SsvPricingPlan } from '~/lib/components/modals/PricingSsvModal';

export interface PricingSsvModalProps {
  ainValues: string;
  isPaymentModalOpen: boolean;
  currentPlan?: SsvPricingPlan;
}

export const initPricingSsvModalProperties: PricingSsvModalProps = {
  ainValues: '',
  isPaymentModalOpen: false,
};
