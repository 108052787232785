export interface Settings {
  isApproveAllErc20PluginEnable: boolean;
  isAdvancedMode: boolean;
  withoutConnectionValidation?: boolean;
}

export interface SettingsModalProps extends Settings {
  handler: (settings: Settings) => void;
}

export const initSettingsModalProperties: SettingsModalProps = {
  handler: () => {},
  isApproveAllErc20PluginEnable: false,
  isAdvancedMode: false,
  withoutConnectionValidation: false,
};
